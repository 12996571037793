.genFilter {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding-left: 20px;
}

.genTitle {
  color: white;
  border: none;
  padding: 0;
  text-align: center;
  font-size: 1.3em;
  line-height: 1.2em;
  margin: 10px 0;
}

.genButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 20px;
}

.genButtonWrapper {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  position: relative;
}

.genButton {
  color: white;
  font-size: 2em;
  text-shadow: -3px -3px 0 #000, 3px -3px 0 #000, -3px 3px 0 #000,
  3px 3px 0 #000;
  line-height: 0.9em;
  font-family: game_version;
  width: 120px;
  height: 50px;
  padding-left: 15px;
  text-align: center;
}

.tick {
  width: 30px;
  height: 2em;
  margin-top: 15px;
  min-width: 30px;
}