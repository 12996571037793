* {
  box-sizing: border-box;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  background: #0f0c29; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #24243e,
    #302b63,
    #0f0c29
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #24243e,
    #302b63,
    #0f0c29
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
@font-face {
  font-family: pokemon;
  src: url(../../resources/fonts/PokemonGB.ttf);
}
@font-face {
  font-family: game_version;
  src: url(../../resources/fonts/FuturaExtraBold.otf);
}
@font-face {
  font-family: pixel;
  src: url(../../resources/fonts/pokemonPixelFont.ttf);
}
@font-face {
  font-family: arcade;
  src: url(../../resources/fonts/ARCADE_N.TTF);
}
.footer {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}
.toMainModeButton {
  padding: 10px 10px 10px 10px;
  color: black;
  display: inline-block;
  background-color: white;
  font-size: 20px;
  text-align: center;
  font-family: pokemon;
  white-space: pre;
  line-height: 1.5;
  margin-right: 10px;
  margin-left: 10px;
  width: 184px;
}
.toTimeTrialButton {
  padding: 10px 10px 10px 10px;
  color: black;
  display: inline-block;
  background-color: white;
  font-size: 20px;
  text-align: center;
  font-family: pokemon;
  white-space: pre;
  line-height: 1.5;
  margin-right: 10px;
  margin-left: 10px;
  width: 184px;
}
* {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.buttons-container {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 20;
  display: flex;
}

.leaderboards-button {
  margin-right: 15px;
}

.donation-button {
  color: #fff;
  background-color: rgb(251, 202, 60) !important;
  border-color: rgb(251, 202, 60) !important;
}

.donation-button:hover,
.donation-button:focus {
  color: #fff;
  background-color: rgb(251, 202, 60) !important;
  border-color: rgb(251, 202, 60) !important;
}

.donation-button:active {
  color: #fff;
  background-color: rgb(232, 187, 58) !important;
  border-color: rgb(232, 187, 58) !important;
}

html {
  overflow-y: hidden;
}