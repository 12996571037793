.resultsModal {
  text-align: center;
  z-index: 9000;
  position: fixed;
  width: 850px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 1);
  border-radius: 10px;
  border: 5px double rgb(255, 255, 255);
  padding: 35px;
}

.modalPkmnEncounteredList {
  display: inline-block;
  width: 80%;
  margin-bottom: 15px;
}

.modalEncounteredPokemonPanel {
  border-radius: 10px;
  border: 5px double white;
  overflow: hidden;
  margin-bottom: 15px;
}

.modalScrollBarRemover {
  height: 90%;
  width: 100%;
  overflow: hidden;
}
.modalEncounteredPokemonList {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-left: 1.7%;
  padding-top: 1%;
  padding-right: 20px; /* Increase/decrease this value for cross-browser compatibility */
  box-sizing: content-box; /* So the width will be 100% + 17px */
}
.modalEncounteredPokemonList > li {
  float: left;
  width: 9%;
  margin: 0.5%;
}

.timeSurvivedDisplay {
  margin-bottom: 15px;
  
}

.modalPokemonsEncounteredTitle {
  font-family: pokemon;
  color: whitesmoke;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  max-width: 100%;
  font-size: 1.8em;
  display: block;
  height: 10%;
  text-align: center;
}

.modalEncounteredPokemon {
  width: 100%;
}

#modalMMButton {
  display: inline-block;
}

#modalTTButton {
  display: inline-block;
}


.new-rank-container {

}

.new-rank-title {
  font-family: pokemon;
  color: whitesmoke;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  max-width: 100%;
  font-size: 1.8em;
  display: block;
  height: 10%;
  text-align: center;
}

.new-rank-explainer {
  font-family: pokemon;
  color: whitesmoke;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  max-width: 100%;
  font-size: 1em;
  display: block;
  height: 10%;
  text-align: center;
  margin-bottom: 15px;
}

.new-rank-input-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.new-rank-label {
  font-family: pokemon;
  color: whitesmoke;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  font-size: 16px;
  line-height: 1em;
  display: block;
  text-align: center;
  margin: 0;
  margin-right: 5px;
}

.new-rank-name-input, .new-rank-name-input:focus, .new-rank-name-input:active, .new-rank-name-input:hover {
  background-color: white;
  border: none;
  font-size: 16px;
  color: black;
  font-family: arcade;
  margin-bottom: 0;
  text-align: center;
  width: 100px;
  margin-right: 10px;
}

.new-rank-save-button {
  padding: 5px 5px 5px 5px;
  color: black;
  display: inline-block;
  background-color: white;
  font-size: 14px;
  text-align: center;
  font-family: pokemon;
  white-space: pre;
  line-height: 1;
  margin-right: 10px;
  margin-left: 10px;
  width: 100px;
  margin-bottom: 25px;
}