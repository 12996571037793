.turn {
  position: relative;
  display: flex;
  margin-bottom: 10px;
  margin-top: 100px;
}

.turn > .headerImg {
  position: fixed;
  top: 2%;
  left: 50%;
  transform: translateX(-50%);
}

.turn-content {
  display: flex;
  flex-direction: row;
  position: relative;
}

.pkmnSpriteShowing {
  animation: appearfullscreen 0.5s reverse;
}

.pokedex {
  width: 750px;
  position: relative;
}

.respuestas {
  position: absolute;
  width: 250px;
  top: 33%;
  left: 59.4%;
}

.pokedexGlow {
  position: absolute;
  left: 3.3%;
  top: 0.2%;
  height: 90px;
  width: 90px;
}

@font-face {
  font-family: calculator;
  src: url(../../resources/fonts/digital-7.ttf);
}

.scoreboard {
  position: absolute;
  top: 81.5%;
  left: 10%;
}

.currentScoreLabel {
  position: absolute;
  top: -36.7%;
  left: 12.7%;
  font-family: calculator;
  font-weight: bold;
  font-size: 20px;
  color: black;
}

.highScoreLabel {
  position: absolute;
  top: 45.5%;
  left: 97%;
  font-family: calculator;
  font-weight: bold;
  font-size: 20px;
  color: black;
}
.currentScore {
  position: absolute;
  top: -5%;
  left: 13%;
  font-family: calculator;
  font-weight: bold;
  font-size: 35px;
  color: black;
}
.highScore {
  position: absolute;
  top: 24.5%;
  left: 66%;
  font-family: calculator;
  font-weight: bold;
  font-size: 35px;
  color: white;
}

.timeLeftLabel {
  position: absolute;
  top: 74.5%;
  left: 14%;
  font-family: calculator;
  font-weight: bold;
  font-size: 20px;
  color: black;
}
.timeLeft {
  position: absolute;
  top: 80%;
  left: 14.5%;
}
.timeLeft > .number {
  font-family: calculator;
  font-weight: bold;
  font-size: 35px;
  color: black;
}
.timeLeft > .unit {
  font-family: pokemon;
  font-weight: bold;
  font-size: 21px;
  color: black;
}
.timeAdded {
  position: absolute;
  top: 80%;
  left: 20.5%;
  animation: blink 0.2s steps(1, start) infinite;
}
.timeAdded > .number {
  font-family: calculator;
  font-weight: bold;
  font-size: 35px;
  color: green;
}
.timeAdded > .unit {
  font-family: pokemon;
  font-weight: bold;
  font-size: 21px;
  color: green;
}
.timeDiscounted {
  position: absolute;
  top: 80%;
  left: 20.5%;
  animation: blink 0.3s steps(1, start) infinite;
}
.timeDiscounted > .number {
  font-family: calculator;
  font-weight: bold;
  font-size: 35px;
  color: red;
}
.timeDiscounted > .unit {
  font-family: pokemon;
  font-weight: bold;
  font-size: 21px;
  color: red;
}

@keyframes blink {
  20% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.pkmnSprite {
  &.fullscreen {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 45%;
    left: 25%;
  }
}

@keyframes appearfullscreen {
  from {
    width: 130px;
    top: 45%;
    left: 25%;
  }
  to {
    width: 170px;
    top: 44%;
    left: 25%;
  }
}