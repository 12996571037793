.songItem > p {
  color: white;
  margin: 0;
}

.songItem > p:hover {
  color: green;
  cursor: pointer;
}
.songList {
  display: inline-block;
  position: absolute;
  left: 10px;
  top: 10px;
  border: white solid 1px;
  padding: 55px 5px 5px 5px;
  border-radius: 10px;
  background-color: rgba(75, 196, 28, 0.5);
  z-index: 10;
}

.noDisplay {
  display: none;
}

.jukeboxToggleButton {
  color: #fff;
  background-color: #4bc41c !important;
  border-color: #4bc41c !important;
}

.jukeboxToggleButton:hover,
.jukeboxToggleButton:focus {
  color: #fff;
  background-color: #4bc41c !important;
  border-color: #4bc41c !important;
}

.jukeboxToggleButton:active {
  color: #fff;
  background-color: #3d9e17 !important;
  border-color: #3d9e17 !important;
}

.jukeboxToggleButton {
  color: #fff;
  background-color: #4bc41c !important;
  border-color: #4bc41c !important;
  padding-left: 25px;
  padding-right: 25px;
  margin-left: 10px;
  margin-right: 15px;
}

.jukeboxToggleButton:hover,
.jukeboxToggleButton:focus {
  color: #fff;
  background-color: #4bc41c !important;
  border-color: #4bc41c !important;
}

.jukeboxToggleButton:active {
  color: #fff;
  background-color: #3d9e17 !important;
  border-color: #3d9e17 !important;
}
