.leaderboard-shadow {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 8999;
  background-color: rgba(0,0,0,0.4);
}

.leaderboard-modal {
  text-align: center;
  z-index: 9000;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 1);
  border-radius: 10px;
  border: 5px double rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px;
}

.leaderboard {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 35px;
}

.leaderboard-entry {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.leaderboard-entry-rank {
  width: 100%;
  font-size: 16px;
  color: white;
  font-family: arcade;
  margin-bottom: 0;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
}

.leaderboard-entry-name {
  width: 100%;
  font-size: 16px;
  color: white;
  font-family: arcade;
  margin-bottom: 0;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
}

.leaderboard-entry-score {
  width: 100%;
  font-size: 16px;
  color: white;
  font-family: arcade;
  margin-bottom: 0;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
}

.leaderboard-button {
  padding: 10px 10px 10px 10px;
  color: black;
  display: inline-block;
  background-color: white;
  font-size: 12px;
  text-align: center;
  font-family: pokemon;
  white-space: pre;
  line-height: 1.5;
  margin-right: 10px;
  margin-left: 10px;
  width: 125px;
}