.answer {
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 8px 10px 2px 10px;
  cursor: pointer;
  width: 250px;
  border: 1px solid black;
  border-radius: 20px;
  background-color: aliceblue;
  border-bottom: 4px solid grey;
  transition: all 0.2s ease;
}

.answer:focus {
  outline: none;
}

.answer > p {
  font-family: pokemon;
  color: black;
  font-size: 16px;
  padding-top: 5px;
  padding-bottom: 8px;
  margin: 0;
}
.answer:hover {
  transform: translateY(-1px);
  background-color: rgb(236, 236, 236);
}
.answer:active {
  transform: translateY(1px);
  background-color: #fff;
  border-bottom: 2px solid grey;
  outline: none;
}

.correctAnswer {
  background-color: green;
  border-bottom: 4px solid rgb(23, 80, 23);
}
.wrongAnswer {
  background-color: red;
  border-bottom: 4px solid rgb(87, 19, 19);
}
.correctAnswer:hover {
  background-color: green;
}
.wrongAnswer:hover {
  background-color: red;
}
.correctAnswer:active {
  background-color: green;
  border-bottom: 2px solid rgb(23, 80, 23);
}
.wrongAnswer:active {
  background-color: red;
  border-bottom: 2px solid rgb(97, 20, 20);
}
